import logo from './assets/theme_asset/logo1.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details








import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function FAQ() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/faqpageapi/${id}`);
      setData(response.data.faq);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null) {
      navigate('/login');
    } else {
      fetchData();
      feather.replace();
    }
  }, []);

  const handleQuestionClick = (index) => {
    setData(data.map((item, i) => {
      if (i === index) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    }));
  };

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (

    <body className="">
    <div>
      <div className="auth-header" style={{height:'150px'}}>
      <div> <div className="custom-container">
      <div className='row'> 
          <div className='col-2'>
          <Link to="/profile" style={{ textDecoration: 'none',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 80px', fontSize:'15px',color:'#42025a',fontFamily:''}}><img src={logo} alt='logo' style={{width:'85px', height:'auto', margin:'-20px 0px',}}/></div>
    </div></div>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>FAQ's</h2>
            <h4 className="p-0">Answers at your fingertips</h4>
            </center></div>
        </div>
      </div>
      <section className="section-b-space">
      <div className="custom-container">
      
      
      </div></section>
      <form className="auth-form" target="_blank">
        <div className="custom-container">
          <div className="help-center">
            <h2 className="fw-semibold">What issues are you facing?</h2>
            {data.map((faq, index) => (
              <div className="accordion accordion-flush help-accordion" key={index}>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className={`accordion-button${faq.isOpen ? '' : ' collapsed'}`} type="button" onClick={() => handleQuestionClick(index)}>
                      {faq.question}
                    </button>
                  </h2>
                  <div className={`accordion-collapse collapse${faq.isOpen ? ' show' : ''}`} >
                    <div className="accordion-body"  dangerouslySetInnerHTML={{ __html: faq.answer }}>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
    </body>
  );
}

export default FAQ;

