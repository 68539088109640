import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';
import { BiClipboard } from 'react-icons/bi';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import color from './assets/theme_asset/color.png';
import number from './assets/theme_asset/number.png';
import card1 from './assets/theme_asset/card1.png';
import game1 from './assets/theme_asset/1.png';
import game3 from './assets/theme_asset/2.png';

import card2 from './assets/theme_asset/card2.jpg';
import game from './assets/theme_asset/game.jpg';
import ban1 from './assets/theme_asset/ban1.png';
import ban2 from './assets/theme_asset/ban2.png';
import l49 from './assets/theme_asset/logo/48.png';
import popup from './assets/theme_asset/wazir_img.jpg'

import search from './assets/search (1).png';

import stakebet from './assets/theme_asset/stakebet.png';

import './App.css';
import './game.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';






function App() {

  const navigate = useNavigate();

  const [copied, setCopied] = useState(false);


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  const [bbnPrice, setBbnPrice] = useState(null);

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  



const dataChartRec = {
  labels: [
    'Active',
    'Hold',
    'Exit'
  ],
  datasets: [{
    label: 'Recommandation Insights',
    data: recMetrics,
    backgroundColor: [
      '#2b8b64',
      '#d18925',
      'black'
    ],
    hoverOffset: 4
  }]
};


      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
     
      setData(response.data);
     
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  const copyToClipboard = () => {
    const userEmail = data.user.email; // Replace this with the actual user's email
const textToCopy = `wazir.co.in/register?email=${encodeURIComponent(userEmail)}`;

  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch((error) => console.error('Could not copy text: ', error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
  
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }
  };

  useEffect(() => {
    // Replace icons after component is mounted
    

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/');
      
    }
    feather.replace();

    fetchData();
  }, []);

 


  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={true} showFooter={true} /> 

       )
    }

   
    const registerLink = `https://www.wazir.co.in/register?email=${data.user.email}`;
  
 
    return (
      <body className=''>
      <div className=''>
    
       
       <Sidebar/>

       <div className="marquee-container" style={{marginTop:'25px',marginBottom:'-20px',backgroundColor:'#017a39',height:'25px',
                                                 fontSize:'18px',color:'white'}}>
      <div className="marquee">
      <span>Welcome to Wazir </span>
      <span>{data.setting.marquee} </span>
      {/* <span>{data.setting.marquee} </span> */}
      {/* <span>Your scrolling text goes here. </span>
      <span>Your scrolling text goes here. </span> */}
        
        {/* Add more spans with your text */}
      </div>
    </div>
       
       {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/iconsax@1.1.5/css/iconsax.css" integrity="sha384-kJ8Bl2nzBRn1Fls+eWnBEqMWvAqNSMIAd1N2fXk4UuI2V5kyAFLtk4WuIqHg4HS7" crossOrigin="anonymous" /> */}
       <br/>
       
<section className="">
  <div className="custom-container">
    <div className="card-box" style={{marginTop:'5px'}}>
      <div className="card-details">
        <div className="d-flex justify-content-between">
          <h5 className="fw-semibold">Play Wallet Balance</h5>
          <img src={ellipse} alt="ellipse" />
        </div>
        <h1 className="mt-2 text-white">₹ {data.wallet.wallet_balance} </h1>
        <div className="amount-details" style={{marginTop:'30px'}}>
          <div className='row'>
            
          <div className="col-12 text-start">
          <h3 style={{color:'white'}}>Hello </h3>
            <h3 style={{color:'white'}}>{data.user.name}</h3><br />
           
      
          </div>
          
            </div>

        </div>
      </div>
      
    </div>
    <div className="d-flex align-items-center justify-content-center">
    <Link to="/deposite" style={{marginRight:'50px', paddingTop:'11px',marginLeft:'-20px'}}>
        <button className='botton' style={{
        padding: '11px 10px' , 
        width: '150%' ,
        marginBottom: '15%',
        borderRadius: '20px',
        backgroundColor: 'green',
        border: 'none',
        color: 'white'}}>Deposit</button></Link>
        <Link to='/withdraw'>
        <button style={{ 
          padding: '10px' , 
        width: '120%' ,
        borderRadius: '20px',
        backgroundColor: 'goldenrod',        
        border: 'none',
        color: 'white'
        }}>Withdraw</button></Link>
    </div>
  </div>
</section>
<div className="custom-container">
<div className='row'>
            
            <div className="col-8 text-start">
              <div className="d-flex align-items-center justify-content-start">
              
                <h5 style={{color:'black'}}>Refferal Link:</h5>
              </div>
              <a href={registerLink}>www.wazir.co.in/register?email={data.user.email}</a><br />
             
        
            </div>
            <div className="col-4 text-start">
              <div className="d-flex ">
              
              {!copied && (
    <button className="btn d-flex btn-link" style={{color:'black'}} onClick={copyToClipboard}>
      Copy <BiClipboard style={{ marginLeft: '2px', color: 'black' }} />
    </button>
  )}
  {copied && <span className="text-success">Copied!</span>}
  
  
                  </div>
              
              </div>
              </div></div>
<section className="categories-section section-b-space">
  <div className="custom-container">
    <ul className="categories-list">
      <li>
        <Link to="/profit">
        <h5 className="mt-2 text-center">Profit</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 32 32">
	<path fill="#008000" d="M21 12v-2h-4V7h-2v3h-2a2.002 2.002 0 0 0-2 2v3a2.002 2.002 0 0 0 2 2h6v3h-8v2h4v3h2v-3h2a2.002 2.002 0 0 0 2-2v-3a2.002 2.002 0 0 0-2-2h-6v-3Z" />
	<path fill="#008000" d="M16 4A12 12 0 1 1 4 16A12.035 12.035 0 0 1 16 4m0-2a14 14 0 1 0 14 14A14.041 14.041 0 0 0 16 2" />
</svg></div>
<h5 className="mt-2 text-center">{data.wallet.win_balance}</h5>
        </Link>
      </li>
      <li>
      <Link to="/revenue">
      <h5 className="mt-2 text-center">Revenue</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 36 36">
	<path fill="#008000" d="m3.5 13.56l1.5-.88V16h26v-3.29l1.48.85a1 1 0 0 0 1-1.73L18 2.92L2.5 11.83a1 1 0 1 0 1 1.73m14.35-6.45a.8.8 0 0 1 .8 0L25.37 11h-3.22l-3.9-2.24l-3.9 2.24h-3.21Z" class="clr-i-solid clr-i-solid-path-1" />
	<path fill="#008000" d="M32.85 27H32v-.85A1.15 1.15 0 0 0 30.85 25H28v-7.37h-4V25h-4v-7.37h-4V25h-4v-7.37H8V25H5.15A1.15 1.15 0 0 0 4 26.15V27h-.85A1.15 1.15 0 0 0 2 28.15V31h32v-2.85A1.15 1.15 0 0 0 32.85 27" class="clr-i-solid clr-i-solid-path-2" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg>
          </div>
          <h5 className="mt-2 text-center">{data.wallet.availabe_revenue}</h5>
        </Link>
      </li>
      <li>
      <Link to="/bonus">
      <h5 className="mt-2 text-center">Rewards</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 24 24">
	<g fill="none" stroke="#008000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<circle cx="12" cy="12" r="10" />
		<path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8m4 2V6" />
	</g>
</svg>
          </div>
          <h5 className="mt-2 text-center">{data.wallet.availabe_level}</h5>
        </Link>
      </li>
      <li>
      <Link to="/Withdraw">
      <h5 className="mt-2 text-center">Withdraw</h5>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="3em" viewBox="0 0 16 16">
	<g fill="#008000">
		<path fill-rule="evenodd" d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm9 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0m-6.25-.75a.75.75 0 1 0 0 1.5a.75.75 0 0 0 0-1.5M11.5 6A.75.75 0 1 1 13 6a.75.75 0 0 1-1.5 0" clip-rule="evenodd" />
		<path d="M13 11.75a.75.75 0 0 0-1.5 0v.179c0 .15-.138.28-.306.255A65.277 65.277 0 0 0 1.75 11.5a.75.75 0 0 0 0 1.5c3.135 0 6.215.228 9.227.668A1.764 1.764 0 0 0 13 11.928z" />
	</g>
</svg>
          </div>
          <h5 className="mt-2 text-center">{data.wallet.withdraw_wallet}</h5>
          
        </Link>
      </li>
    </ul>
  </div>
</section>

{/* <section style={{ justifyContent: 'center', paddingBottom: '0px' }} className='mb-1'>
  <div className="custom-container" style={{ margin: '0 auto' }}>
    <Slider {...settings}>
      <div>
        <img src={ban1} alt='color' style={{ width: '100%', height: '100%', borderRadius: '25px', padding: '10px' }} />
      </div>
      <div>
        <img src={ban2} alt='color' style={{ width: '100%', height: '100%', borderRadius: '25px', padding: '10px' }} />
      </div>
      <div>
        <img src={stakebet} alt='game' style={{ width: '100%', height: '100%', borderRadius: '25px', padding: '10px' }} />
      </div>
    </Slider>
  </div>
</section> */}


<section style={{paddingTop:'10px'}}>
<div className="custom-container" >
<div className="row gy-3">
  <div className="col-12">
    <div className="transaction-box" style={{backgroundColor:'#d9ffd2'}}>
      <div className="d-flex gap-3">
        <div className="transaction-image">
          <img
            className="img-fluid transaction-icon"
            src={game1}
            alt="p1"
            style={{borderRadius:'10px'}}
          />
        </div>
        <div className="transaction-details">
          <div className="transaction-name">
            <div>
          <h2 style={{color:"green"}}>FutureFlare: </h2>
          <h5 style={{color:"green"}}>Predict & Win</h5>
          </div>
            <Link to="/game1"> <h5 className="" style={{backgroundColor:'green',padding:'5px 15px',borderRadius:'10px',color:'white'}}>Play</h5></Link>
          </div>
          {/* <div className="d-flex justify-content-between">
            <h5 className="light-text">Installment</h5>
            <h5 className="light-text">9:00 pm</h5> 
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="col-12">
    <div className="transaction-box" style={{backgroundColor:'#d9ffd2'}}>
      <div className="d-flex gap-3">
        <div className="transaction-image">
          <img
            className="img-fluid transaction-icon"
            src={game3}
            alt="p2"
            style={{borderRadius:'10px'}}
          />
        </div>
        <div className="transaction-details">
          <div className="transaction-name">
          <h2 style={{color:"green"}}>Lucky Draw</h2>
          <h5 className="" style={{backgroundColor:'green',padding:'5px 15px',borderRadius:'10px',color:'white'}}>Comming Soon</h5>
         
          </div>
          {/* <div className="d-flex justify-content-between">
            <h5 className="light-text">Installment</h5>
            <h5 className="light-text">9:00 pm</h5>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="col-12">
    <div className="transaction-box" style={{backgroundColor:'#d9ffd2'}}>
      <div className="d-flex gap-3">
        <div className="transaction-image">
          <img
            className="img-fluid transaction-icon"
            src={game3}
            alt="p2"
            style={{borderRadius:'10px'}}
          />
        </div>
        <div className="transaction-details">
          <div className="transaction-name">
          <h2 style={{color:"green"}}>Avaitor</h2>
          <h5 className="" style={{backgroundColor:'green',padding:'5px 15px',borderRadius:'10px',color:'white'}}>Comming Soon</h5>
         
          </div>
          {/* <div className="d-flex justify-content-between">
            <h5 className="light-text">Installment</h5>
            <h5 className="light-text">9:00 pm</h5>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="col-12">
    <div className="transaction-box" style={{backgroundColor:'#d9ffd2'}}>
      <div className="d-flex gap-3">
        <div className="transaction-image">
          <img
            className="img-fluid transaction-icon"
            src={game1}
            alt="p1"
            style={{borderRadius:'10px'}}
          />
        </div>
        <div className="transaction-details">
          <div className="transaction-name">
            <h2 style={{color:"green"}}>Rummy & 3 Patti </h2>
            <h5 className="" style={{backgroundColor:'green',padding:'5px 15px',borderRadius:'10px',color:'white'}}>Comming Soon</h5>
          </div>
          {/* <div className="d-flex justify-content-between">
            <h5 className="light-text">Installment</h5>
            <h5 className="light-text">9:00 pm</h5> 
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="col-12">
    <div className="transaction-box" style={{backgroundColor:'#d9ffd2'}}>
      <div className="d-flex gap-3">
        <div className="transaction-image">
          <img
            className="img-fluid transaction-icon"
            src={game1}
            alt="p1"
            style={{borderRadius:'10px'}}
          />
        </div>
        <div className="transaction-details">
          <div className="transaction-name">
            <h2 style={{color:"green"}}>Poker</h2>
            <h5 className="" style={{backgroundColor:'green',padding:'5px 15px',borderRadius:'10px',color:'white'}}>Comming Soon</h5>
          </div>
          {/* <div className="d-flex justify-content-between">
            <h5 className="light-text">Installment</h5>
            <h5 className="light-text">9:00 pm</h5> 
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div>

  </div>
  
</section> 
{/* <section>
<div className="custom-container" >
  <div className='row' style={{textAlign:'center'}}>
    <div className='col'>
     <Link to="/game1"> <img src={game1} alt='color' style={{ width: '150px', height: '150px', borderRadius:'25px'  }} />
     <h3 style={{color:'goldenrod'}}>WAZIR Play</h3></Link>
    </div>
    <div className='col'>
    <Link to="/network"> <img src={game3} alt='number' style={{ width: '150px', height: '150px', borderRadius:'25px'  }} />
      <h3 style={{color:'goldenrod'}}>WAZIR Earning</h3></Link>
    </div>
    
  </div>
  </div>
  
</section>  */}


<section className="panel-space" />

        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>

</div>

</body>
  
  );
  }

  export default App;
