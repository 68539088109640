import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';
import frame from './assets/theme_asset/frame.png';


import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import reward from './assets/theme_asset/reward.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';
import Pagination from './react-pegination';






function Bonus() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [id, setId] = useState(''); // Define and initialize the id state
  const [upi, setUpi] = useState('');
  const [totalAmount1, setTotalAmount1] = useState(0);
  const [transfer , settransfer] = useState('');

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
      const totalAmount1 = response.data.wallet.bonus_wallet;
      setTotalAmount1(totalAmount1);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'amount') setAmount(value);
    if (name === 'transfer') settransfer(value);

  };
  
  const handleSubmit = async (e,totalAmount1) => {
    e.preventDefault();
    try {
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      formData.append('transfer', transfer);
     // Assume data.user.id is the ID you want to encode
      let encodedId = btoa(data.user.id);
      formData.append('id', encodedId);

      // Append receipt file to FormData
      
     
      if (totalAmount1 < amount) {
        setModalMessage({
          title: 'Insufficient Balance',
          content: 'To withdraw bouns,Please check the available balance',
        });
        setShowModal(true);
        return;
      }
      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/BonusWithdraw`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });

      
        
      setModalMessage({
        title: 'Amount Transfered Successfully',
        content: 'Now You Can Play With The Bonus Amount',
      });
      setShowModal(true);
      navigate('/bonus'); 
      fetchData();
    } catch (error) {
      console.error('Error updating user:', error);
     
      setModalMessage({
        title: 'Error',
        content: 'Some thing went wrong. Please insert all details or try again later',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/bonus');
    }
    feather.replace();
  
    fetchData();
  }, []);
  

  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const reasonMap = {
    
    'direct_recharge_gift': 'Direct Recharge Gift',
    'bonus_transafer_withdraw': 'Transfer to Withdraw Wallet',
    'bonus_transafer_play': 'Transfer to Play Wallet',
    'level': `Betting Rewards Level` ,
    
  };

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (

    <body className='' >
    <div className='' style={{overflow:'hidden'}}>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       
     <Sidebar />
     <section style={{marginTop:'35px'}}>
     <center><h2 style={{color:'black'}} >Reward Wallet</h2> </center>
      <div className="row">
        <div className='col-6'>
        <center>
        <div>
          
        </div>
        <div className='card-1'>
          Total Earning <br/>
          ₹ {data.wallet.total_bonus} 
        </div></center></div>
        <div className='col-6'><center>
        <div>
          
        </div>
        <div className='card-1'>
          Available Balance <br/>
          ₹ {data.wallet.availabe_level} 
        </div></center></div></div>
      </section>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form-1" onSubmit={(e) => handleSubmit(e, totalAmount1)} encType="multipart/form-data">
        <div className="custom-container">
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label">Amount</label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="Enter Amount"
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />
              
            </div>
          </div>
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Where to Transfer</label>
            <div className="form-input">
            <div className="custom-select">
            <select
                className="form-control"
                id="transfer"
                name="transfer"
                onChange={handleChange}
                required
                >
                <option value="">Select where to Transfer</option>
                <option value="withdraw">Transfer to Withdraw</option>
                <option value="playWallet">Transfer to Play Wallet</option>
                </select>
                
                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span> {/* Down arrow icon */}
        </div>

               
              
            </div>
          </div>
          
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}>{transfer === '' && 'Transfer'}{transfer === 'withdraw' && 'Transfer to Withdraw Wallet'}{transfer === 'playWallet' && 'Transfer to Play Wallet' } </button>
        </div>
      </form>
      {data.bonus_report.data && data.bonus_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.bonus_report.data.map((bonus, index) => (
        <div className="col-12" key={index}>
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img
                  className="img-fluid"
                  src={reward}
                  alt="p1"
                />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{reasonMap[bonus.reason]} {bonus.reason === 'level' ? bonus.receipt : ' '  }</h5>
                  <h3 className={bonus.reason === 'bonus_transafer_withdraw' ? 'error-color' :bonus.reason === 'bonus_transafer_play' ? 'error-color' : 'success-color'}>
                  ₹ {bonus.amount} 
                  </h3>
                </div>
                <div className="transaction-name">
                  <h5>{new Date(bonus.created_at).toLocaleDateString([], { day: '2-digit', month: '2-digit',year: '2-digit' })} {new Date(bonus.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.bonus_report.links && (
        <Pagination 
          pageCount={data.bonus_report.last_page} 
          onPageChange={handlePageClick} 
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No data available</div>
)}

       <Footer />
        
      </div>
      </body>
  );
}

 


export default Bonus;