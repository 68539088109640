import logo1 from './logo.svg';
import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import atm from './assets/theme_asset/atm.png';

import { BiSolidLeftArrow } from 'react-icons/bi';

import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from './react-pegination';






function Withdraw_report() {

  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);


  const fetchData = async () => {
    try {
     
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
      

     
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/withdraw_report');
    }
    feather.replace();

    fetchData();
  }, []);


  const reasonMap = {
    'withdraw_payment_usdt': 'Payment Withdraw in USDT',
    'withdraw_payment_inr': 'Payment Withdraw in INR',
    'withdraw_transafer_play' : 'Transfered to Play Wallet',
    'profit_transafer_withdraw' : 'Received from Profit Wallet',
    'bonus_transafer_withdraw' : 'Received from Team Rewards Wallet',
    'level_transafer_to_withdraw' : 'Received from Level Income Wallet',
    'revenue_transafer_withdraw' : 'Received from Revenue Wallet',
  };

  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

   
  
 
    return (
      <body className=''>
      <div >
     

      <div className="custom-container">
        <div className='row'> 
          <div className='col-2'>
          <Link to="/profile" style={{ textDecoration: 'none',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#008000" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 80px', fontSize:'15px',color:'#42025a',fontFamily:''}}><img src={logo} alt='logo' style={{width:'85px', height:'auto', margin:'-20px 0px',}}/></div>
    </div>
    </div>
       
      
    <section><br/>
  <div className="custom-container">
  
    <div className="title" style={{justifyContent:'center'}}>
        <center><h2 >Withdraw Wallet History</h2></center>
    </div>
    {data.withdraw_report.data && data.withdraw_report.data.length > 0 ? (
  <section>
    <div className="custom-container">
      {data.withdraw_report.data.map((withdraw, index) => (
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img className="img-fluid " src={atm} alt="p1" />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                  <h5>{reasonMap[withdraw.reason]}</h5>
                  <h3 className={withdraw.status === 'pending' ? 'text-black' : withdraw.status === 'reject' ? 'error-color' : 'success-color'}>
                 {withdraw.reason === 'withdraw_payment_usdt' ? '$' : '₹'}{withdraw.amount}</h3>
                </div>
                <div className="transaction-name">
                  <h5>
                    {new Date(withdraw.created_at).toLocaleDateString([], {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}{' '}
                    {new Date(withdraw.created_at).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <h3 className={withdraw.status === 'pending' ? 'text-black' : withdraw.status === 'reject' ? 'error-color' : 'success-color'}>
  {withdraw.status}
</h3>

                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {data.withdraw_report.links && (
        <Pagination
          pageCount={data.withdraw_report.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No transactions available</div>
)}

          
       </div>
       </section>
       </div>
       </body>
  );
  }

  export default Withdraw_report;
