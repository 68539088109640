import React, { useEffect, useState } from 'react';
import feather from 'feather-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';


import './App.css';

import logo1 from './assets/theme_asset/logo/logo.png';
import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import home_bg from './assets/theme_asset/background/home-bg.jpg';

import logo from './assets/theme_asset/logo.png';

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      
     
    } catch (error) {

    } finally {

    }
  };
  useEffect(() => {
    // Check if token exists
    const token = localStorage.getItem('token');
    if (!token) {
      // If no token exists, navigate to login
      navigate('/login');
    }

    // Replace icons after component is mounted
    feather.replace();
    fetchData();
    
  }, []);


  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };
  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
          
  return (
    <div className=''>
      <div className={`offcanvas sidebar-offcanvas offcanvas-start${isSidebarOpen ? ' show' : ''}`} tabIndex={-1} id="offcanvasLeft">
          <div className="offcanvas-header sidebar-header">
          <a  onClick={closeSidebar}>
            <i className="categories-icon"  data-feather="x" />
                  </a>

            <div className="sidebar-logo">
              <img className="img-fluid logo" src={logo} alt="logo" />
              
            </div>
             
            <div className="balance">
              <img className="img-fluid balance-bg" src={home_bg} alt="auth-bg" />
              <h2>WAZIR</h2>
              <h5>Welcome to WAZIR</h5>
            </div>
             
          
            
          </div>
          <div className="offcanvas-body">
            <div className="sidebar-content">
              <ul className="link-section">
                <li>
                  <Link to="/" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#008000" d="M10.995 4.68v3.88A2.44 2.44 0 0 1 8.545 11h-3.86a2.38 2.38 0 0 1-1.72-.72a2.41 2.41 0 0 1-.71-1.72V4.69a2.44 2.44 0 0 1 2.43-2.44h3.87a2.42 2.42 0 0 1 1.72.72a2.39 2.39 0 0 1 .72 1.71m10.75.01v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.73-.71a2.44 2.44 0 0 1-.71-1.73V4.69a2.39 2.39 0 0 1 .72-1.72a2.42 2.42 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44m0 10.75v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.75-.69a2.42 2.42 0 0 1-.71-1.73v-3.87a2.391 2.391 0 0 1 .72-1.72a2.421 2.421 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44zm-10.75.01v3.87a2.46 2.46 0 0 1-2.45 2.43h-3.86a2.42 2.42 0 0 1-2.43-2.43v-3.87A2.46 2.46 0 0 1 4.685 13h3.87a2.49 2.49 0 0 1 1.73.72a2.45 2.45 0 0 1 .71 1.73" />
</svg>
                    <h3>Home</h3>
                  </Link>
                </li>
               
                <li>
                  <Link to="/deposite" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#008000" d="M15 3a1 1 0 0 1 1 1v2h4a1 1 0 0 1 1 1v12h2v2H1v-2h2V7a1 1 0 0 1 1-1h4V4a1 1 0 0 1 1-1zm-5 5H8v11h2zm6 0h-2v11h2zm-2-3h-4v1h4z" />
</svg>
                    <h3>Deposit</h3>
                  </Link>
                </li>
                {/* <li>
                  <Link to="page-list.html" className="pages">
                    <i className="sidebar-icon" data-feather="file-text" />
                    <h3>Theme Pages</h3>
                  </Link>
                </li> */}
                <li>
                  <Link to="/revenue" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 36 36">
	<path fill="#008000" d="m3.5 13.56l1.5-.88V16h26v-3.29l1.48.85a1 1 0 0 0 1-1.73L18 2.92L2.5 11.83a1 1 0 1 0 1 1.73m14.35-6.45a.8.8 0 0 1 .8 0L25.37 11h-3.22l-3.9-2.24l-3.9 2.24h-3.21Z" class="clr-i-solid clr-i-solid-path-1" />
	<path fill="#008000" d="M32.85 27H32v-.85A1.15 1.15 0 0 0 30.85 25H28v-7.37h-4V25h-4v-7.37h-4V25h-4v-7.37H8V25H5.15A1.15 1.15 0 0 0 4 26.15V27h-.85A1.15 1.15 0 0 0 2 28.15V31h32v-2.85A1.15 1.15 0 0 0 32.85 27" class="clr-i-solid clr-i-solid-path-2" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg>
                    <h3>Daily Revenue</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/profit" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
	<path fill="#008000" d="M21 12v-2h-4V7h-2v3h-2a2.002 2.002 0 0 0-2 2v3a2.002 2.002 0 0 0 2 2h6v3h-8v2h4v3h2v-3h2a2.002 2.002 0 0 0 2-2v-3a2.002 2.002 0 0 0-2-2h-6v-3Z" />
	<path fill="#008000" d="M16 4A12 12 0 1 1 4 16A12.035 12.035 0 0 1 16 4m0-2a14 14 0 1 0 14 14A14.041 14.041 0 0 0 16 2" />
</svg>
  <h3>My Bet Profit</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/bonus" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="#008000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<circle cx="12" cy="12" r="10" />
		<path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8m4 2V6" />
	</g>
</svg>
                    <h3>Team Rewards</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/direct_team" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="#008000" d="M8 2.002a1.998 1.998 0 1 0 0 3.996a1.998 1.998 0 0 0 0-3.996M12.5 3a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m-9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3M5 7.993A1 1 0 0 1 6 7h4a1 1 0 0 1 1 1v3a3.02 3.02 0 0 1-.146.927A3.001 3.001 0 0 1 5 11V8zM4 8c0-.365.097-.706.268-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 0 0 3.436 2.319A3.983 3.983 0 0 1 4 10.999zm8 0v3c0 .655-.157 1.273-.436 1.819A2.5 2.5 0 0 0 15 10.5V8a1 1 0 0 0-1-1h-2.268c.17.294.268.635.268 1" />
</svg>
                    <h3>My Direct Team</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="#008000" d="M8 2.002a1.998 1.998 0 1 0 0 3.996a1.998 1.998 0 0 0 0-3.996M12.5 3a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m-9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3M5 7.993A1 1 0 0 1 6 7h4a1 1 0 0 1 1 1v3a3.02 3.02 0 0 1-.146.927A3.001 3.001 0 0 1 5 11V8zM4 8c0-.365.097-.706.268-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 0 0 3.436 2.319A3.983 3.983 0 0 1 4 10.999zm8 0v3c0 .655-.157 1.273-.436 1.819A2.5 2.5 0 0 0 15 10.5V8a1 1 0 0 0-1-1h-2.268c.17.294.268.635.268 1" />
</svg>
                    <h3>Team</h3>
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#008000" d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2" />
</svg> <h3>Wazir Newsfeed</h3>
                  </Link>
                </li>
               
                <li>
                  <Link to="/about" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#008000" d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4c1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10" />
</svg>
                    <h3>Support</h3>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/about" className="pages">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#008000" d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2" />
</svg>
                    <h3>About us</h3>
                  </Link>
                </li> */}
                <li>
                <Link to="#" onClick={handleLogout} className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 1024 1024">
	<path fill="#008000" d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8c-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9a353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8c47.9 0 94.3 9.3 137.9 27.8c42.2 17.8 80.1 43.4 112.7 75.9c7.9 7.9 15.3 16.1 22.4 24.5c3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82C271.7 82.6 79.6 277.1 82 516.4C84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7c3.4-5.3-.4-12.3-6.7-12.3m88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6" />
</svg>
                    <h3>Log out</h3>
                  </Link>
                </li>
              </ul>
              {/* <h2 style={{color:'black' ,marginTop:'15px'}}>Contact us</h2> 
            <div>Support:<a href='mailto:wazir.in24@gmail.com'> wazir.in24@gmail.com</a></div> 
            <div>Whatsapp:<a href='https://wa.me/12135945630'> https://wa.me/12135945630</a></div> */}
            </div>
          </div>
        </div>
      <header className="section-t-space">
      
        <div className="custom-container" style={{marginTop:'10px'}}>
          <div className="header-panel">
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <i className="menu-icon" data-feather="menu" />
            </button>
            <img className="img-fluid logo" src={logo} style={{width:'120px' , height:'120px'}} alt="logo" />
            </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;

                 
                