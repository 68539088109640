import logo1 from './logo.svg';

import logo from './assets/theme_asset/logo.png';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import big from './assets/theme_asset/token/big.png';
import small from './assets/theme_asset/token/small.png';

import countdown from './assets/theme_asset/countdown.mp3';

import back from './assets/theme_asset/back.png';

import { BiSolidLeftArrow } from 'react-icons/bi';


import './game.css';

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';


import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from './react-pegination';
import WinPopup from './winpopup';






function Game1() {

  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [amount, setAmount] = useState('');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const [selectedToken, setSelectedToken] = useState(null);

  const [quantity, setQuantity] = useState(1); // State for quantity
  const [selectedTab, setSelectedTab] = useState('Transaction');

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [totalAmount1, setTotalAmount1] = useState(0);
  const location = useLocation();

  const [showWinPopup, setShowWinPopup] = useState(false);

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);


  const [selectedTokenBackground, setSelectedTokenBackground] = useState('');

  const handleTokenClick = (token) => {
    setSelectedToken(token);
    setSelectedColor(null);
    setselectedcondition(null); // Reset selectedColor when a token is clicked
    setShowPopup(true);
    setSelectedItem(token);

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  
 
const fetchData = async () => {
  try {
   

    const id = localStorage.getItem('id');
    const response = await axios.get(`${apiBaseUrl}/gamepageapi/${id}?page=${pageNumber + 1}`);
    setData(response.data);
 

    const totalAmount1 = response.data.wallet.wallet_balance;

    const  startTime  = response.data.slot3.start_time;
    const  endTime  = response.data.slot3.end_time;


    const  serverTime  = new Date(response.data.serverTime).getTime();

  
    var currentTime = new Date().getTime(); // Current time in milliseconds

    const ofsetTime =  serverTime - currentTime;


    currentTime = currentTime + ofsetTime;
 // Check the condition after data is fetched
 

    // Calculate time remaining for the game
    const timeDiff = new Date(endTime).getTime() - currentTime;
    setTimeRemaining(timeDiff > 0 ? timeDiff : 0);

    setTotalAmount1(totalAmount1);

    const { color, token } = response.data;
    // Set the selected item based on color or token
    setSelectedItem(color || token);
    
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};

const fetchData1 = async () => {
  try {
  

    const id = localStorage.getItem('id');
    const response = await axios.get(`${apiBaseUrl}/gamepageapi/${id}?page=${pageNumber + 1}`);
    setData(response.data);
 

    const totalAmount1 = response.data.wallet.wallet_balance;

    const  startTime  = response.data.slot3.start_time;
    const  endTime  = response.data.slot3.end_time;


    const  serverTime  = new Date(response.data.serverTime).getTime();

  
    var currentTime = new Date().getTime(); // Current time in milliseconds

    const ofsetTime =  serverTime - currentTime;


    currentTime = currentTime + ofsetTime;
 // Check the condition after data is fetched
 if (response.data.winnerData1Min.winning_amount > 0) {
  setShowWinPopup(true);

  // Set showWinPopup to false after 3 seconds
  setTimeout(() => {
    setShowWinPopup(false);
  }, 3000);
} else {
  // Handle the case where data or winnerData1Min is null or winning_amount is not greater than 0
}



    // Calculate time remaining for the game
    const timeDiff = new Date(endTime).getTime() - currentTime;
    setTimeRemaining(timeDiff > 0 ? timeDiff : 0);

    setTotalAmount1(totalAmount1);

    const { color, token } = response.data;
    // Set the selected item based on color or token
    setSelectedItem(color || token);
    
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};

  const handleFormSubmit = async (e, totalAmount1) => {
    e.preventDefault();
    try {
      const amount = e.target.amount.value;
      const id1 = e.target.id.value;
      const slot_id = e.target.slot_id.value;
      const reason = e.target.reason.value;
      const quantity = e.target.quantity.value;
      const inputcode = e.target.inputcode.value;

      const id = btoa(id1);

      if (totalAmount == 0 ) {
        setModalMessage({
          title: 'Please Select The Amount',
          content: 'To play the game, please select your amount.',
        });
        setShowModal(true);
        
        // Prevent form submission if condition is true
        return;
      }
  
  
      const response = await axios.post(`${apiBaseUrl}/playgame`, {
        amount,
        id,
        quantity,
        slot_id,
        reason,
        inputcode,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
  
      if (totalAmount1 < totalAmount ) {
        setModalMessage({
          title: 'Insufficient Balance',
          content: 'To play the game, please recharge your amount.',
        });
        setShowModal(true);
        
        // Prevent form submission if condition is true
        return;
      }

      
  
      handleClosePopup();
      fetchData();
      navigate('/game1');
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error, show error message, etc.
    }
  };
  

  const closeModal = () => {
    setShowModal(false);
  };
  

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedcondition, setselectedcondition] = useState(null);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setSelectedToken(null);
    setselectedcondition(null);
    setShowPopup(true);
    setSelectedItem(color);// Update selected item with token

  };
      

 
  

  const [selectedItem, setSelectedItem] = useState(''); // Combined selected color/token


  const [timeRemaining, setTimeRemaining] = useState(0);

  // Fetch slot data from backend API
 




     useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            fetchData();
          }
        };
      
        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);
      
        // Fetch data initially if the tab is already visible
        
      
        // Cleanup event listener
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);
      
    
    useEffect(() => {

        
      const timer = setInterval(() => {
        setTimeRemaining(prevTime => {
          const newTime = prevTime > 0 ? prevTime - 1000 : 0;
          if (newTime === 0) {
            // If time reaches zero, refresh the page
              fetchData1();
            
          }
          return newTime;
        });
      }, 1000);
    
      // Cleanup timer
      return () => clearInterval(timer);
    }, [timeRemaining]);
    
    const formatTime = () => {
      const minutes = Math.floor(timeRemaining / 60000);
      const seconds = Math.floor((timeRemaining % 60000) / 1000);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    
    const handleChange = (e) => {
      const { name, value, files } = e.target;
  
      if (name === 'amount') setAmount(value);
    };
    
    
  const [totalAmount, setTotalAmount] = useState(0);


  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  
  const handleAmountButtonClick = (value) => {
    setAmount(parseInt(value, 10)); // Parse the value to an integer
};


  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10); // Parse the input value to an integer
    setQuantity(newQuantity);
};

  const getBackgroundColor = () => {

   
    // Define the mapping of tokens to colors
    const tokenColorMap = {
        0: '#006d08',
        1: '#006d08',
        2: '#006d08',
        3: '#006d08',
        4: '#006d08',
        5: '#006d08',
        6: '#006d08',
        7: '#006d08',
        8: '#006d08',
        9: '#006d08',
        'red': '#006d08',
        'blue': '#006d08',
        'green': '#006d08',
        'big' : '#006d08',
        'small' : '#006d08',
      };
  
      // Check if the selected token is in the mapping
      if (selectedToken !== null && tokenColorMap[selectedToken]) {
        return tokenColorMap[selectedToken];
      } else if (selectedColor !== null && tokenColorMap[selectedColor]) {
        return tokenColorMap[selectedColor];
      } else if (selectedcondition !== null && tokenColorMap[selectedcondition]) {
        return tokenColorMap[selectedcondition];
      }else {
        return '#FFFFFF'; // Return default color if neither token nor color is selected
      }
};


 
  
  

    const getImageForSelectedValue = (no) => {
      switch(no) {
        case '0':
          return token1;
        case '1':
          return token2;
        case '2':
          return token3;
        case '3':
          return token4;
        case '4':
          return token5;
        case '5':
          return token6;
        case '6':
          return token7;
        case '7':
          return token8;
        case '8':
          return token9;
        case '9':
          return token10;
        case 'red':
          return color2;
        case 'blue':
          return color1;
        case 'green':
          return color3;
          case 'big' :
            return big;
          case'small' :
            return small;

        // Add cases for other values up to 12
        default:
          return null; // Return null if no image matches the selected value
      }
    }
    const getImageForSelectedValue1 = (SelectedValue) => {
        switch(SelectedValue) {
          case '0':
            return token1;
          case '1':
            return token2;
          case '2':
            return token3;
          case '3':
            return token4;
          case '4':
            return token5;
          case '5':
            return token6;
          case '6':
            return token7;
          case '7':
            return token8;
          case '8':
            return token9;
          case '9':
            return token10;
          case 'red':
            return color2;
          case 'blue':
            return color1;
          case 'green':
            return color3;
          case 'big' :
            return big;
          case'small' :
            return small;
  
          // Add cases for other values up to 12
          default:
            return null; // Return null if no image matches the selected value
        }
      }

    const [showPopup30Sec, setShowPopup30Sec] = useState(false);

// Effect to monitor time remaining and show popup when time reaches 30 seconds
useEffect(() => {
  // Check if time remaining is less than 30000
  if (timeRemaining < 8000) {
    setShowPopup30Sec(true);
    handleClosePopup();
    // After 30 seconds, hide the popup
    const popupTimer = setTimeout(() => {
      setShowPopup30Sec(false);
    }, 8000 - timeRemaining); // Adjust timeout based on remaining time

    // Cleanup timer
    return () => clearTimeout(popupTimer);
  } else {
    // If time remaining is not less than 30000, hide the popup
    setShowPopup30Sec(false);
  }
}, [timeRemaining]);



useEffect(() => {
    // Replace icons after component is mounted
    

      
       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/game1');
    }
    feather.replace();

    setTotalAmount(quantity * amount);
  }, [quantity, amount]);

 

  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

    return (
      <body className=''>
      <div >
          {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

          {showWinPopup && <WinPopup />}
        <div className={`background-container ${showPopup ? 'blur-background' : ''}`} >
        
        <div className="custom-container">
        <div className='row'> 
          <div className='col-2'>
        <Link to="/" style={{ textDecoration: 'none',marginTop:'15px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#008000" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style={{marginTop:'5px'}}>
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </Link></div>
    <div className='col-10' style={{padding: '5px 0 0 80px', fontSize:'15px',color:'#42025a',fontFamily:''}}><img src={logo} alt='logo' style={{width:'85px', height:'auto', margin:'-20px 0px',}}/></div>
    </div>
    </div>
    
      <section className="">
      <div className="custom-container">
     
      <section> 
      <div className="row">
        <div className='col-6'>
        <center>
        <div>
          
        </div>
        <div className='card-1' style={{width:'100%'}}>
          Play Wallet <br/>
          ₹ {data.wallet.total_playwallet} <br/>
        <Link to="/deposite"><button className='botton' style={{
        padding: '11px 10px' , 
        width: '100%' ,
        marginTop: '10px',
        borderRadius: '20px',
        backgroundColor: 'rgb(5 181 0)',
        border: 'none',
        color: 'white'}}>Deposit</button></Link>
        </div></center></div>
        <div className='col-6'><center>
        <div>
          
        </div>
        <div className='card-1' style={{width:'100%'}}>
         Profit Wallet<br/>
          ₹ {data.wallet.win_balance} <br/>
          <Link to='/profit'>
        <button style={{ 
          padding: '10px' , 
        width: '100%' ,
        marginTop:'10px',
        borderRadius: '20px',
        backgroundColor: 'rgb(218 165 32)',        
        border: 'none',
        color: 'white'
        }}>Profit Wallet</button></Link>
        </div></center></div></div>
        
        </section>
{/*   
    <div className="card-box">
      <div className="card-details">
      <div className="d-flex " style={{justifyContent:'space-around'}}>
          <h5 className="fw-semibold" style={{paddingLeft:'25px'}}>Play Wallet</h5>
          <h5 className="fw-semibold">Profit Wallet</h5>
        </div>
        <div className="d-flex " style={{justifyContent:'space-around'}}>
        <h1 className="mt-2 text-white" style={{textAlign:'center'}}>₹ {data.wallet.total_playwallet}</h1>
        <h1 className="mt-2 text-white" style={{textAlign:'center'}}>₹ {data.wallet.win_balance}</h1>
        </div>
        <div className="amount-details" style={{display:'block'}}>
        <div className="d-flex align-items-center justify-content-center">
    <Link to="/deposite" style={{marginRight:'50px', paddingTop:'11px',marginLeft:'-20px'}}>
        <button className='botton' style={{
        padding: '11px 10px' , 
        width: '150%' ,
        marginBottom: '15%',
        borderRadius: '20px',
        backgroundColor: 'rgb(5 181 0)',
        border: 'none',
        color: 'white'}}>Deposit</button></Link>
        <Link to='/profit'>
        <button style={{ 
          padding: '10px' , 
        width: '120%' ,
        borderRadius: '20px',
        backgroundColor: 'rgb(218 165 32)',        
        border: 'none',
        color: 'white'
        }}>Profit Wallet</button></Link>
    </div>

        </div>
      </div>
      
    </div> */}
    
  </div>
</section>
       

<div className="title" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
      <Link to='/game1'>
        <h2 style={{ cursor: 'pointer',color: location.pathname === '/game1' ? 'white' : 'black' ,backgroundColor: location.pathname === '/game1' ? 'rgb(218 165 32)' : 'transparent' }}>1 Minute</h2>
      </Link>
      <Link to='/game'>
        <h2 style={{ cursor: 'pointer',color: location.pathname === '/game' ? 'white' : 'black', backgroundColor: location.pathname === '/game' ? 'rgb(218 165 32)' : 'transparent' }}>3 Minute</h2>
      </Link>
      <Link to='/game5'>
        <h2 style={{ cursor: 'pointer',color: location.pathname === '/game5' ? 'white' : 'black', backgroundColor: location.pathname === '/game5' ? 'rgb(218 165 32)' : 'transparent' }}>5 Minute</h2>
      </Link>
    </div>
       {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/iconsax@1.1.5/css/iconsax.css" integrity="sha384-kJ8Bl2nzBRn1Fls+eWnBEqMWvAqNSMIAd1N2fXk4UuI2V5kyAFLtk4WuIqHg4HS7" crossOrigin="anonymous" /> */}
       
       
       <section>
        <div>
        <div className="custom-container">
        <div className="game-card">
      <div className="game-info">
        <h2 className="time">Starts in: <span>{formatTime()}</span></h2>
        <h3 className="color">{data.slot3.hashed_slot_id}</h3>
      </div>
    </div></div>
        </div>
       </section>


      

       <section style={{position: 'relative',borderRadius:'25px',backgroundColor:'#69e58a61',marginTop:'10px'}}>
  
    <div>
      <div className='custom-container'>
        <div className='token-container'>
          <div className=''>
            <div className='row_np row-cols-5' style={{marginLeft:'70px'}}>
              <div className='col '></div>
             <img
              className={`col m-1 token-img ${selectedColor === 'blue' ? 'rotate' : ''}`}
              src={color1}
              alt='token1'
              onClick={() => handleColorSelect('blue')}
            />
            
            
             <img
              className={`col m-1 token-img ${selectedColor === 'red' ? 'rotate' : ''}`}
              src={color2}
              alt='token2'
              onClick={() => handleColorSelect('red')}
            />
           
           
             
               <img
              className={`col m-1 token-img ${selectedColor === 'green' ? 'rotate' : ''}`}
              src={color3}
              alt='token3'
              onClick={() => handleColorSelect('green')}
            />
            <div className='col'></div>
            </div>
          </div>
        </div>
      </div>
   
      <div className='custom-container'>
        <div className='token-container'>
          <div className='row_np row-cols-5'>
            <img
              className={`col token-img ${selectedToken === 'token1' ? 'rotate' : ''}`}
              src={token1}
              alt='token1'
              onClick={() => handleTokenClick('0')}
            />
            <img
              className={`col token-img ${selectedToken === 'token2' ? 'rotate' : ''}`}
              src={token2}
              alt='token2'
              onClick={() => handleTokenClick('1')}
            />
            <img
              className={`col token-img ${selectedToken === 'token3' ? 'rotate' : ''}`}
              src={token3}
              alt='token3'
              onClick={() => handleTokenClick('2')}
            />
            <img
              className={`col token-img ${selectedToken === 'token4' ? 'rotate' : ''}`}
              src={token4}
              alt='token4'
              onClick={() => handleTokenClick('3')}
            />
            <img
              className={`col token-img ${selectedToken === 'token5' ? 'rotate' : ''}`}
              src={token5}
              alt='token5'
              onClick={() => handleTokenClick('4')}
            />
          </div>
          
          <div className='row_np row-cols-5'>
            <img
              className={`col token-img ${selectedToken === 'token6' ? 'rotate' : ''}`}
              src={token6}
              alt='token6'
              onClick={() => handleTokenClick('5')}
            />
            <img
              className={`col token-img ${selectedToken === 'token7' ? 'rotate' : ''}`}
              src={token7}
              alt='token7'
              onClick={() => handleTokenClick('6')}
            />
            <img
              className={`col token-img ${selectedToken === 'token8' ? 'rotate' : ''}`}
              src={token8}
              alt='token8'
              onClick={() => handleTokenClick('7')}
            />
            <img
              className={`col token-img ${selectedToken === 'token9' ? 'rotate' : ''}`}
              src={token9}
              alt='token9'
              onClick={() => handleTokenClick('8')}
            />
            <img
              className={`col token-img ${selectedToken === 'token10' ? 'rotate' : ''}`}
              src={token10}
              alt='token10'
              onClick={() => handleTokenClick('9')}
            />
          </div>
        </div>
      </div>
    </div>

    <div className='custom-container'>
        <div className='token-container'>
        <div className='row_np row-cols-5' style={{marginLeft:'105px'}}>
            <img
              className={`col token-img ${selectedcondition === 'big' ? 'rotate' : ''}`}
              src={big}
              alt='token6'
              onClick={() => handleColorSelect('big')}
            />
           <img
              className={`col token-img ${selectedcondition === 'small' ? 'rotate' : ''}`}
              src={small}
              alt='token7'
              onClick={() => handleColorSelect('small')}
            />
            
         
        </div>
      </div>
        </div>
  {showPopup30Sec && (
    <div className="custom-popup-container">
      <div className="custom-popup-content">
       
        <div className="custom-countdown-container">
          <div className="custom-countdown-circle">
            <svg>
              <circle cx="25" cy="25" r="24"></circle>
              <text x="50%" y="50%" textAnchor="middle" dy="0.3em">
                {Math.ceil(timeRemaining / 1000)}
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )}
  {/* Overlay */}
  {showPopup30Sec && <div className="overlay"> </div>}
</section>

    <section><br/>
  <div className="custom-container">
  <div className="title" style={{display:'flex', justifyContent:'space-between' }}>
    <h2 className={selectedTab === 'Transaction' ? 'active' : ''} onClick={() => setSelectedTab('Transaction')} style={{ cursor: 'pointer' }}>Transaction</h2>
    <h2 className={selectedTab === 'MyOrder' ? 'active' : ''} onClick={() => setSelectedTab('MyOrder')} style={{ cursor: 'pointer' }}>My History</h2>
</div>

    
{selectedTab === 'Transaction' && (
        <div>
          
              {data.slotone && data.slotone.length > 0 && (
  <section >
  
  <div className="custom-container">
 
      {data.slotone.map((slotone, index) => (
        <div className="row ">
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex ">
              
              <div className="transaction-details1">
                <div className="transaction-name">
                <h5 className="light-text">{slotone.hashed_slot_id}</h5>
                <h5 className="light-text">{new Date(slotone.end_time).toLocaleDateString([], { day: '2-digit', month: '2-digit',year: '2-digit' })} {new Date(slotone.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h5>            </div>
            </div>
            <div className="transaction-details1">
                <div className="transaction-image">
              <img
                  className="img-fluid "
                  src={getImageForSelectedValue(slotone.no)}
                  alt="p1"
                  style={{marginRight:'10px', marginLeft:'130px'}}
                />
                <img
                  className="img-fluid "
                  src={getImageForSelectedValue(slotone.color)}
                  alt="p1"
                  style={{marginRight:'10px'}}
                />
                 <img
                  className="img-fluid "
                  src={getImageForSelectedValue(slotone.bigsmall)}
                  alt="p1"
                />
                  </div>
                </div>
                
            </a>
          </div>
        </div>
        </div>
      ))}
    </div>
  </section>
)}

          </div>
      )}
 {selectedTab === 'MyOrder' && (
  <div>
    {data.game_report.gameplay1.data && data.game_report.gameplay1.data.length > 0 && (
      <section>
        <div className="custom-container">
          {data.game_report.gameplay1.data.map((game_report, index) => (
            <div className="row" key={index}>
              {data.game_report.slot[index] && (
                <div className="col">
                  <div className="transaction-box d-flex flex-row">
                    <div className="transaction-details1">
                      <h5 className="light-text">{data.game_report.slot[index].hashed_slot_id}</h5>
                      <h3 className={game_report.status === 'winner' ? 'success-color' : game_report.status === 'pending' ? 'alert-color' : 'error-color'}>
                        {game_report.status === 'winner' ? '+' + game_report.winning_amt + ' ₹' :game_report.status === 'pending' ?  game_report.amount + ' ₹' : '-' + game_report.amount + ' ₹'}
                      </h3>
                    </div>
                    <div className="transaction-details1">
                      <h3 className='light-text'>
                        {new Date(game_report.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </h3>
                      <h3 className={game_report.status === 'winner' ? 'success-color' :game_report.status === 'pending' ? 'alert-color' : 'error-color'}>
                        {game_report.status === 'winner' ? 'win' : game_report.status === 'pending' ? 'in process' : 'loss'}
                      </h3>
                    </div>
                    <div className="transaction-image">
                      <img className="img-fluid" src={getImageForSelectedValue1(game_report.selected_value)} alt="p1" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {data.game_report.gameplay1.links && (
            <Pagination 
              pageCount={data.game_report.gameplay1.last_page} 
              onPageChange={handlePageClick} 
            />
          )}
        </div>
      </section>
    )}
    {!data.game_report.gameplay1.data || data.game_report.gameplay1.data.length === 0 && (
      <div className="custom-container">
        <div style={{textAlign:'center'}}>No transactions available</div>
      </div>
    )}
  </div>
)}

    
  </div>
</section>   </div>
{showPopup && (
    
        <div className={`popup-container active`} style={{backgroundColor:'white'}} >
          <div className='row'>
<div className='col-10'>
      <h6 className="mt-3 text-center dark-text">
        Availble Balance : <div className="" style={{fontSize:'20px',color:'black'}}>₹ {data.wallet.wallet_balance}</div>
      </h6>
      
      </div>
      <button onClick={handleClosePopup} style={{height:'30px', width:'30px' ,padding:'0'}} className='btn btn-danger col-2'>X</button>

      </div>
      <form onSubmit={(e) => handleFormSubmit(e, totalAmount1)}>

            <section className="section-b-space">

  <div className="custom-container">
    <div className="currency-transfer">
      
        <div className="form-group">
          <label htmlFor="inputcard" className="form-label mb-2" style={{color:'black'}}>
          Your Predicted For Play 
          </label><br />
          <img className="img-fluid" src={getImageForSelectedValue1(selectedItem)} alt="p1" 
          style={{width:'50px',marginLeft:'50%',translate:'-50%'}}/>
          <div className="d-flex gap-2 mb-2">
          <input
              type="hidden"
              className="form-control"
              id="inputcode"
              name="inputcode"
              value={selectedItem}
              placeholder="Enter your IBAN"
              readOnly
            />
          </div>
        </div>
        {/* <div className="form-group">
      <div className='row mb-2'>
        <label className="form-label col-4" style={{color:'black'}}>Quantity:</label>
        <Link to='#' className="col-2 btn-amount" style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={handleDecrement}>-</Link>
        <input type="number" className="col-2 m-1" name="quantity" id="quantity"  value={quantity} onChange={handleQuantityChange} />
        <Link to='#' className="col-2 btn-amount" style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={handleIncrement}>+</Link>
      </div>
      <div className='row mb-3'>
        <div className='col-2'></div>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(1)}>x1</Link>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(10)}>x10</Link>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(50)}>x50</Link>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(100)}>x100</Link>
      </div>
    </div> */}
    <input type="hidden" className="col-2 m-1" name="quantity" id="quantity"  value={quantity} onChange={handleQuantityChange} />
    
        <div className="form-group">
          <div className="form-input">
          <div className='row mb-3'>
          <div className='col-3' style={{color:'black'}}> Amount:</div>
          <div className='col-9 mb-3'>
          <input
              type="text"
              className="form-control "
              id="amount"
              name="amount"
              placeholder="Enter your Amount"
              value={totalAmount}
              onChange={(e) => setAmount(e.target.value)}
            /></div>
            <div className='col-3'></div>
          <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('10')}>10</Link>
            <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('20')}>20</Link>
            <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('50')}>50</Link>
            <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('100')}>100</Link>
            <div className='col-3 mt-2'></div>
          <Link to="#" className='col-2 mt-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('200')}>200</Link>
            <Link to="#" className='col-2 mt-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('500')}>500</Link>
            <Link to="#" className='col-2 mt-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('1000')}>1000</Link>
            <Link to="#" className='col-2 mt-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('1500')}>1500</Link>
         
         </div>   
         <input
              type="hidden"
              className="form-control"
              id="id"
              name="id"
              placeholder="Enter your Amount"
              value={data.user.id}
            />
             <input
              type="hidden"
              className="form-control"
              id="slot_id"
              name="slot_id"
              placeholder="Enter your Amount"
              value={data.slot3.id}
            />
            <input
              type="hidden"
              className="form-control"
              id="reason"
              name="reason"
              placeholder="Enter your Amount"
              value='one_minute'
            />
            
          </div>
        </div>
        
        <div className=" row">
        <div className='col-1'></div>
        
        <input
              type="submit"
              className="close-btn col-12"
              style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }}
              id="submit"
              name="submit"
              value="Submit"
            />
         </div>
      
     
   
    </div>
  </div>
</section>
  
</form>

        </div>
      )}
</div>
</body>
  );
  }

  export default Game1;