import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';
import frame from './assets/theme_asset/frame.png';


import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function Withdraw() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [dividedAmount, setDividedAmount] = useState('');
  const [password , setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [modalMessage1, setModalMessage1] = useState({});
  const [id, setId] = useState(''); // Define and initialize the id state
  const [upi, setUpi] = useState('');
  const [transfer , settransfer] = useState('');
  const [amount_type , setamount_type] = useState('');


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (amount !== '') {
      const divided = amount / 92;
      setDividedAmount(divided.toFixed(2));
    } else {
      setDividedAmount('');
    }
  }, [amount]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'amount') setAmount(value);
    if (name === 'password') setPassword(value);
    if (name === 'dividedamount') setDividedAmount(value);
    if (name === 'transfer') settransfer(value);
    if (name === 'amount_type') setamount_type(value);


  };
  
  const handleSubmit = async (e , withdraw_wallet) => {
    e.preventDefault();
    try {
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      formData.append('password', password);
      formData.append('transfer', transfer);
      formData.append('amount_type', amount_type);
      let encodedId = btoa(data.user.id);
      formData.append('id', encodedId);
      formData.append('dividedamount', dividedAmount);

      // Append receipt file to FormData

      if(data.withdraw_wallet < amount){
        setModalMessage({
          title: 'Insufficeient Balance',
          content: 'Amount you entered is greter than the available Balance',
        });
        setModalMessage(true);

      }
      
      for (let [key, value] of formData.entries()) {
        // console.log(key + ': ' + value);
      }
  
      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/paymentWithdraw`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });
      setModalMessage1({
        title: 'Withdraw Request send Successfully',
        content: 'You will receive your Withdraw amount with in 1 hour successfully',
      });
      setShowModal1(true);
      navigate('/withdraw'); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error('Error updating user:', error);
      setModalMessage({
        title: 'Withdraw Request Failed',
        content: 'Please Check Password or Minimum Winthdraw Amount/Insufficient Balance',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const closeModal1 = () => {
    setShowModal1(false);
   // Assuming you have fetched user ID and amount from your data
const userId = data.user.email; // Replace with actual user ID
const userId1 = data.user.name; // Replace with actual user ID

// Construct the withdrawal request message with dynamic user ID and amount
const withdrawRequestMessage = encodeURIComponent(`Hello! 👋 Just wanted to let you know that user ${userId1} with ${userId} has initiated a withdrawal request of ₹ ${amount} from their account. Kindly confirm once you've processed it. Thank you!`);

// Replace placeholders with actual phone number
const phoneNumber = '7219169907';

// Construct the WhatsApp URL with the message
const whatsappURL = `https://wa.me/${phoneNumber}?text=${withdrawRequestMessage}`;

// Redirect to the WhatsApp chat
window.location.href = whatsappURL;
};
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/withdraw');
    }
    feather.replace();
  
    fetchData();
  }, []);
  

  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (

    <body className='' style={{overflow:'hidden'}}>
    <div className=''>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
     {showModal1 && <Modal message={modalMessage1} closeModal={closeModal1} />}
     <Sidebar />
     <section style={{marginTop:'35px'}}>
        <center>
        <div>
          <h2 style={{color:'black'}} >Withdrawal Wallet</h2> 
        </div></center>
        <div className="row">
          
        <div className=''>
        <center>
        <div>
          
        </div>
        <div className='card-1'>
          Available Balance <br/>
          ₹ {data.wallet.withdraw_wallet} 
        </div></center></div>
        </div>
        
      </section>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form-1" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="custom-container">
        <div className="form-group mb-2">
          <label htmlFor="amount" className="form-label" style={{color:'black'}}>Amount in INR</label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="Enter Amount"
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />

                    
              
            </div>
          </div>
       
          
          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Where to Transfer</label>
            <div className="form-input">
            <div className="custom-select">
            <select
                className="form-control"
                id="transfer"
                name="transfer"
                onChange={handleChange}
                required
                >
                <option value="">Select where to Transfer</option>
                <option value="withdraw">Withdraw Amount</option>
                <option value="playWallet">Transfer to Play Wallet</option>
                </select>

                <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span>
               
              </div>
            </div>
          </div>
          {transfer === 'withdraw' && <div className="form-group mb-2">
    <label htmlFor="amount" className="form-label" style={{color:'black'}}>Select Amount Type</label>
    <div className="form-input">
        <div className="custom-select">
            <select
                className="form-control"
                id="amount_type"
                name="amount_type"
                onChange={handleChange}
                required
            >
                <option value="">Select Amount Type</option>
                <option value="inr">Transfer to Bank(INR)</option>
                <option value="usdt">Transfer to USDT</option>
            </select>
            
            <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" fill-rule="non-zero" d="M13.069 5.157L8.384 9.768a.546.546 0 0 1-.768 0L2.93 5.158a.552.552 0 0 0-.771 0a.53.53 0 0 0 0 .759l4.684 4.61a1.65 1.65 0 0 0 2.312 0l4.684-4.61a.53.53 0 0 0 0-.76a.552.552 0 0 0-.771 0" />
</svg></span> {/* Down arrow icon */}
        </div>
    </div>
</div>

      }

       {amount_type === 'usdt' && <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Amount in USDT</label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="dividedamount"
                name="dividedamount"
                value={dividedAmount}
                placeholder="Enter Amount"
                onChange={handleChange}
                readOnly
              />
  
            </div>
          </div>}


          <div className="form-group mb-2">
            <label htmlFor="amount" className="form-label" style={{color:'black'}}>Enter Your password (for verification):</label>
            <div className="form-input">
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Enter Your Password "
                onChange={handleChange}
                required
              />

            
              
            </div>
          </div>
        
          {amount_type === 'usdt' && <div className="form-group">
            <div className="form-input" style={{color:'black'}}><br/><br/>
              Your USDT(Bep20) Address is : <div>{data.user.upi_id}</div>
              
            </div><br/>
          </div> }
          {amount_type === 'inr' && <div className="form-group">
            <div className="form-input" style={{color:'black'}}><br/><br/>
              Your Bank Details : 
              <div>Bank Name: {data.user.bank_name}</div>
              <div>Bank Account Number: {data.user.acc_no}</div>
              <div>IFSC Code: {data.user.ifsc}</div>
              
            </div><br/>
          </div> }
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}>{transfer === '' && 'Withdraw' }{transfer === 'withdraw' && 'Withdraw' }{transfer === 'playWallet' && 'Tranfer to Play Wallet' }</button>
        </div>
      </form>
      <div className="custom-container" >
  <div className="withdraw-info">
    
    <div className="withdraw-details">
      {/* <div className="detail">1. Need to bet ₹{data.wallet.remaining} to be able to withdraw</div> */}
      <div className="detail" style={{color:'blue',display:'flex'}}>1. Withdrawal time  <div style={{color:'red'}}> 00:00-23:59</div></div>
      <div className="detail" style={{color:'blue'}}>2. Minimum Withdrawal amount range for INR is ₹ 100 </div>
      <div className="detail" style={{color:'blue'}}>3. Minimum Withdrawal amount range for USDT is ₹ 1000 </div>
      <div className="detail" style={{color:'blue'}}>4. Standard deduction applied</div>


      <div style={{textAlign:'center' , fontSize:'15px',fontWeight:'bold'}}>Need Support :<br/><Link to="/about">Click Here</Link></div>
    </div>
   
  </div>
</div>

       <Footer />
        
      </div>
      </body>
  );
}

 


export default Withdraw;